import { memo, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { LinkWrapper } from "../../../../apps/web/app/components/linkWrapper";
import useTranslate from "../../../../apps/web/app/hooks/useTranslate";
import { RandomImage } from "./randomImage";

export const LatestGraphics = memo(({ products }: { products?: any }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { translate } = useTranslate();

  return (
    <div className="bg-white py-[53px]">
      <p className="font-montserrat text-center text-xl font-medium uppercase lg:mt-16 lg:text-3xl xl:text-[36px]">
        {translate("latestGraphicsTitle")}
      </p>
      <p className="font-poppins text-textdark mx-3 mt-2 text-center text-sm lg:mx-auto lg:mt-5 lg:w-1/2 xl:w-[35%]">
        {translate("latestGraphicsSubtitle")}
      </p>
      <Swiper
        className="mt-7 flex lg:mx-3 lg:mt-20"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        navigation={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: 3,
          },
        }}
        onSlideChange={(index) => setActiveIndex(index.activeIndex)}
      >
        {products.map((product: any, index: any) => (
          <SwiperSlide key={index} className="relative px-2">
            <LinkWrapper href={`products/${product.handle}`}>
              <RandomImage product={product} />
              <div className="mx-6 mt-5 hidden md:block">
                <div className="flex">
                  <div className="flex w-1/2 flex-col">
                    <span className="font-poppins text-sm font-semibold uppercase leading-tight">
                      {product.title}
                    </span>
                    <span className="text-textdark text-sm leading-tight">
                      {product.variants.nodes[0].price.currencyCode +
                        " " +
                        product.variants.nodes[0].price.amount}
                    </span>
                  </div>
                  <div className="flex w-1/2 justify-end gap-3 text-[15px]">
                    <LinkWrapper href={`/products/${product.handle}`}>
                      <button className="font-poppins border border-black px-3 py-1.5">
                        {translate("detailsButton")}
                      </button>
                    </LinkWrapper>
                  </div>
                </div>
              </div>
            </LinkWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mt-5 md:hidden">
        <div className="flex">
          <div className="flex w-2/3 flex-col">
            <span className="font-poppins text-sm font-semibold uppercase leading-tight">
              {products[activeIndex] ? products[activeIndex].title : ""}
            </span>
            <span className="text-textdark text-sm leading-tight">
              {products[activeIndex]
                ? products[activeIndex].variants.nodes[0].price.currencyCode +
                  " " +
                  products[activeIndex].variants.nodes[0].price.amount
                : ""}
            </span>
          </div>
          <div className="flex w-1/3 justify-end gap-3 text-[15px]">
            {products[activeIndex] && (
              <LinkWrapper href={`/products/${products[activeIndex].handle}`}>
                <button className="font-poppins border border-black px-3 py-1.5">
                  {translate("detailsButton")}
                </button>
              </LinkWrapper>
            )}
          </div>
        </div>
        <div className="flex justify-center">
          <LinkWrapper
            href="mx-graphics"
            className="bg-neongreen font-poppins mt-7 w-full whitespace-nowrap px-3 py-1.5 text-center text-base font-light text-black"
          >
            <span>{translate("latestGraphicsCTA")}</span>
            <ArrowRightIcon className="float-right h-full w-4" />
          </LinkWrapper>
        </div>
      </div>
      <div className="mt-14 hidden justify-center lg:flex">
        <LinkWrapper
          href="mx-graphics"
          className="bg-neongreen font-poppins mt-7 flex items-center gap-2 whitespace-nowrap px-10 py-1.5 text-base font-light text-black"
        >
          <span>{translate("latestGraphicsCTA")}</span>
          <ArrowRightIcon className="float-right h-full w-4" />
        </LinkWrapper>
      </div>
    </div>
  );
});
