import { memo } from 'react'

import { ShoppingCart } from '../../assets/svgs/ShoppingCart'
import { ImageComponent } from '../../../../apps/web/app/components/image'
import ktm00101 from '../../assets/images/scooters/ktm00101.jpeg'
import ktm00102 from '../../assets/images/scooters/ktm00102.jpeg'
import ktm00103 from '../../assets/images/scooters/ktm00103.jpeg'
import ktm00104 from '../../assets/images/scooters/ktm00104.jpeg'
import ktm00201 from '../../assets/images/scooters/ktm00201.jpeg'
import ktm00202 from '../../assets/images/scooters/ktm00202.jpeg'
import ktm00203 from '../../assets/images/scooters/ktm00203.jpeg'
import ktm00204 from '../../assets/images/scooters/ktm00204.jpeg'
import { Producers, RecentlyAdded, SearchForm } from '../..'
import { LinkWrapper } from '../../../../apps/web/app/components/linkWrapper'

const items = [
    {
        image: ktm00101,
        name: 'ktm00101',
    },
    {
        image: ktm00102,
        name: 'ktm00102',
    },
    {
        image: ktm00103,
        name: 'ktm00103',
    },
    {
        image: ktm00104,
        name: 'ktm00104',
    },
    {
        image: ktm00201,
        name: 'ktm00201',
    },
    {
        image: ktm00202,
        name: 'ktm00202',
    },
    {
        image: ktm00203,
        name: 'ktm00203',
    },
    {
        image: ktm00204,
        name: 'ktm00204',
    },
]

export const Item = memo(() => {
    return (
        <div className="bg-white">
            <div className="mx-auto flex max-w-[1536px] flex-col md:flex-row md:justify-stretch md:px-5 md:pb-96 md:pt-20">
                <div className="md:w-[350px] md:min-w-[350px] lg:min-w-[416px]">
                    {/* <SearchForm /> */}
                    <div className="hidden md:block">
                        <Producers />
                        <RecentlyAdded />
                    </div>
                </div>
                <div className="grid flex-1 grid-cols-1 bg-white xl:grid-cols-2">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className="font-poppins bg-white px-8 py-8"
                        >
                            <img src={item.image} alt="img" />
                            <div className="mt-5 flex items-center justify-between">
                                <div className="flex flex-col text-[15px] leading-tight">
                                    <span className="font-semibold uppercase leading-tight">
                                        {item.name}
                                    </span>
                                    <span className="text-textdark text-sm leading-tight">
                                        lei 637,00
                                    </span>
                                </div>
                                <div className="flex gap-3">
                                    <button className="font-poppins border border-black p-1.5  ">
                                        <ShoppingCart />
                                    </button>
                                    <LinkWrapper href="product">
                                        <button className="border border-black px-8 py-1.5 text-sm  ">
                                            Details
                                        </button>
                                    </LinkWrapper>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="md:hidden">
                    <Producers />
                    <RecentlyAdded />
                </div>
            </div>
        </div>
    )
})
