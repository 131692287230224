import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const InstallationGuideComponent = () => {
    const { translate } = useTranslate()

    return (
        <div className="bg-white">
            <div className="font-poppins text-textdark mx-auto max-w-screen-xl bg-white px-8 py-12 text-sm font-[400] lg:py-16">
                <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                    {translate('installHeading0')}
                </p>
                <ul className="mt-5 list-disc pl-4 font-light">
                    <li className="pb-4">{translate('need1')}</li>
                    <li className="pb-4">{translate('need2')}</li>
                    <li className="pb-4">{translate('need3')}</li>
                    <li className="pb-4">{translate('need4')}</li>
                    <li className="pb-4">{translate('need5')}</li>
                </ul>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('installHeading1')}
                    </p>
                    <ul className="mt-5 list-disc pl-4 font-light">
                        <li className="pb-4 font-semibold">
                            {translate('installSubheading1')}
                        </li>
                    </ul>
                    <p className="font-light">{translate('preparationP1')}</p>
                    <ul className="mt-5 list-disc pl-4 font-light">
                        <li className="pb-4 font-semibold">
                            {translate('installSubheading2')}
                        </li>
                    </ul>
                    <p className="font-light">{translate('preparationP2')}</p>
                    <p className="mt-5 font-light">
                        {translate('installSubheading3')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('preparationP41') + ' '}
                        <span className="font-semibold">
                            {translate('preparationP42')}
                        </span>
                        {' ' + translate('preparationP43')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('preparationP5')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('preparationP6')}
                    </p>
                    <p className="mt-5 font-semibold uppercase">Important</p>
                    <ol className="mt-5 pl-4 font-light">
                        <li className="pb-4">
                            <span className="font-semibold">1. </span>
                            {translate('important1')}
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">2. </span>
                            {translate('important2')}
                        </li>
                    </ol>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('installHeading2')}
                    </p>
                    <ul className="mt-5 list-disc pl-4 font-light">
                        <li className="pb-4">
                            <span className="font-semibold">
                                {translate('application11')}
                            </span>
                            {' ' + translate('application12')}
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">Step 2 - </span>
                            {translate('application2')}
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">Step 3 - </span>
                            {translate('application3')}
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">Step 4 - </span>
                            {translate('application41') + ' '}
                            <span className="font-semibold">
                                {translate('application42')}
                            </span>
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">Step 5 - </span>
                            {translate('application51') + ' '}
                            <span className="font-semibold">
                                {translate('application52')}
                            </span>
                        </li>
                    </ul>
                    <p className="mt-5 font-semibold uppercase">Important</p>
                    <ol className="mt-5 pl-4 font-light">
                        <li className="pb-4">
                            <span className="font-semibold">1. </span>
                            {translate('important3')}
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">2. </span>{' '}
                            {translate('important4')}
                        </li>
                        <li className="pb-4">
                            <span className="font-semibold">3. </span>
                            {translate('important5')}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
