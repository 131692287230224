import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { memo } from 'react'

export const SearchForm = memo(() => {
    return (
        <div className="bg-white px-8 py-8">
            <p className="font-montserrat border-b-[3px] border-black pb-1.5 font-medium uppercase">
                Search for Products
            </p>
            <div className="relative mt-5 flex items-center bg-[#f0f0f0]">
                <span className="flex-1">
                    <input
                        className="text-textdark font-poppins w-full bg-transparent px-2 py-2 text-sm outline-none"
                        placeholder="Search"
                    ></input>
                </span>
                <span>
                    <MagnifyingGlassIcon className="text-textdark right-0 mx-2 h-full w-5" />
                </span>
            </div>
        </div>
    )
})
