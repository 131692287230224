import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface GalleryProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  images: string[];
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
}

export const Gallery = memo(
  ({ open, onClose, images, currentIndex, setCurrentIndex }: GalleryProps) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [zoomed, setZoomed] = useState<boolean>(false);

    const onUpdate = useCallback(
      ({ x, y, scale }: { x: number; y: number; scale: number }) => {
        const { current: img } = imgRef;
        if (img) {
          const value = make3dTransformValue({ x, y, scale });
          img.style.setProperty("transform", value);
        }
      },
      []
    );

    return (
      <>
        {open ? (
          <div className="fixed h-[100dvh] w-screen bg-white z-[9999] top-0 p-6 flex flex-col sm:hidden">
            <div className="flex justify-end">
              <button onClick={() => onClose(false)}>
                {/* <FontAwesomeIcon
                  icon={faXmark}
                  width={32}
                  height={32}
                  color="gray"
                /> */}
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
                </svg>
              </button>
            </div>
            <div className="flex-1 flex flex-col pb-4 gap-4">
              <div className="flex-1 flex items-center justify-center">
                <QuickPinchZoom
                  key={currentIndex}
                  centerContained
                  onUpdate={onUpdate}
                >
                  <img ref={imgRef} src={images.at(currentIndex)} alt="img" />
                </QuickPinchZoom>
              </div>
              <div className="flex justify-center gap-2 flex-nowrap overflow-scroll">
                {images.map((image: string, index: number) => (
                  <div
                    key={index}
                    className={`overflow-hidden w-14 h-14 rounded-lg border ${
                      currentIndex === index && "border-black"
                    } min-w-[40px]`}
                    onClick={() => {
                      setCurrentIndex(index);
                      setZoomed(false);
                    }}
                  >
                    <img
                      alt="img"
                      src={image}
                      key={index}
                      className="object-contain h-full"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
);
