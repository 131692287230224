import React from "react";
import { ThemeProvider } from "@material-tailwind/react";

export const AppWrapper: React.FC<any> = ({ children }) => {
    return (
        <ThemeProvider>
            <>{children}</>
        </ThemeProvider>
    );
};
