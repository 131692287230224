import { memo, useState } from "react";
import { generateRandomNumber } from "../../../../../apps/web/app/lib/functions";

export const RandomImage = memo(({ product }: { product: any }) => {
  const [imageIndex, setImageIndex] = useState<number>(0);
  return (
    <div className="relative">
      <img
        onMouseOver={() => {
          setImageIndex(
            generateRandomNumber(product?.media?.nodes?.length - 1 || 0)
          );
        }}
        onMouseOut={() => setImageIndex(0)}
        src={product.media.nodes[imageIndex].image.url}
        alt="motorcycle"
      />
    </div>
  );
});
