export const ShoppingCartIcon = (props: any) => {
    return (
        <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75 12.5C5.25 12.5208 4.83333 12.6875 4.5 13C4.1875 13.3333 4.02083 13.75 4 14.25C4.02083 14.75 4.1875 15.1667 4.5 15.5C4.83333 15.8125 5.25 15.9792 5.75 16C6.25 15.9792 6.66667 15.8125 7 15.5C7.3125 15.1667 7.47917 14.75 7.5 14.25C7.47917 13.75 7.3125 13.3333 7 13C6.66667 12.6875 6.25 12.5208 5.75 12.5ZM5.75 15C5.29167 14.9583 5.04167 14.7083 5 14.25C5.04167 13.7917 5.29167 13.5417 5.75 13.5C6.20833 13.5417 6.45833 13.7917 6.5 14.25C6.45833 14.7083 6.20833 14.9583 5.75 15ZM17.8125 2.40625C17.6042 2.13542 17.3333 2 17 2H3.34375L3 0.375C2.91667 0.145833 2.75 0.0208333 2.5 0H0.5C0.1875 0.0208333 0.0208333 0.1875 0 0.5C0.0208333 0.8125 0.1875 0.979167 0.5 1H2.09375L4.5 11.625C4.58333 11.8542 4.75 11.9792 5 12H15.5C15.8125 11.9792 15.9792 11.8125 16 11.5C15.9792 11.1875 15.8125 11.0208 15.5 11H5.40625L5.1875 10H14.875C15.2292 10 15.5312 9.90625 15.7812 9.71875C16.0521 9.51042 16.2396 9.25 16.3438 8.9375L17.9688 3.21875C18.0521 2.92708 18 2.65625 17.8125 2.40625ZM15.375 8.65625C15.2917 8.86458 15.125 8.97917 14.875 9H5C5 9 4.98958 9 4.96875 9C4.96875 9 4.95833 9 4.9375 9L3.59375 3L17 2.9375L15.375 8.65625ZM14.25 12.5C13.75 12.5208 13.3333 12.6875 13 13C12.6875 13.3333 12.5208 13.75 12.5 14.25C12.5208 14.75 12.6875 15.1667 13 15.5C13.3333 15.8125 13.75 15.9792 14.25 16C14.75 15.9792 15.1667 15.8125 15.5 15.5C15.8125 15.1667 15.9792 14.75 16 14.25C15.9792 13.75 15.8125 13.3333 15.5 13C15.1667 12.6875 14.75 12.5208 14.25 12.5ZM14.25 15C13.7917 14.9583 13.5417 14.7083 13.5 14.25C13.5417 13.7917 13.7917 13.5417 14.25 13.5C14.7083 13.5417 14.9583 13.7917 15 14.25C14.9583 14.7083 14.7083 14.9583 14.25 15Z"
                fill={props.color}
            />
        </svg>
    )
}
