import { memo } from 'react'

export const Header = memo((props: any) => {
    return (
        <div className="mt-[112.5px] flex h-[180px] flex-col items-center justify-center bg-[#eeeeee] px-4 lg:h-[250px]">
            <h1 className="font-montserrat text-center text-xl font-medium uppercase lg:mt-5 lg:text-4xl">
                {props.title}
            </h1>
            <p className="font-poppins mt-2 text-center text-sm font-light md:w-2/3 lg:mt-2 lg:w-2/5 lg:text-base">
                {props.body}
            </p>
        </div>
    )
})
