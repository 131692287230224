import {memo, useState, useEffect, useRef} from 'react';

//@ts-ignore
import englishFlag from 'public/assets/images/english.png';
//@ts-ignore
import romanianFlag from 'public/assets/images/romanian.jpeg';
import {useMatches, useFetcher, useRevalidator} from '@remix-run/react';
import {redirectFunction} from '~/lib/utils';

export const LanguageSwitcher = memo(() => {
  const matches = useMatches();
  const fetcher = useFetcher();

  const [language, setLanguage] = useState<string>('');
  const [timesClicked, setTimesClicked] = useState<number>(0);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const revalidator = useRevalidator();

  useEffect(() => {
    if (language) {
      setRedirectUrl(redirectFunction(matches[1].pathname, language));
    }
  }, [language]);

  useEffect(() => {
    if (redirectUrl) {
      submitButtonRef.current?.click();
    }
  }, [redirectUrl]);

  return (
    <fetcher.Form
      replace
      method="post"
      action="/"
      className="flex gap-2 items-center"
    >
      <input name="locale" id="locale" type="hidden" value={language} />
      <input name="path" id="path" type="hidden" value={redirectUrl} />
      <button
        onClick={() => {
          setLanguage('en');
        }}
      >
        <img
          style={{maxHeight: 20, maxWidth: 20}}
          className="max-w-5 h-5 max-h-5 w-5"
          src={englishFlag}
          alt="english"
        />
      </button>
      <button
        onClick={() => {
          setLanguage('ro');
        }}
      >
        <img
          style={{maxHeight: 20, maxWidth: 20}}
          className="max-w-5 h-5 max-h-5 w-5"
          src={romanianFlag}
          alt="english"
        />
      </button>
      <button type="submit" className="hidden" ref={submitButtonRef}>
        Submit
      </button>
    </fetcher.Form>
  );
});
