export const UserIcon = (props: any) => {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 8C8.125 7.97917 9.07292 7.59375 9.84375 6.84375C10.5938 6.07292 10.9792 5.125 11 4C10.9792 2.875 10.5938 1.92708 9.84375 1.15625C9.07292 0.40625 8.125 0.0208333 7 0C5.875 0.0208333 4.92708 0.40625 4.15625 1.15625C3.40625 1.92708 3.02083 2.875 3 4C3.02083 5.125 3.40625 6.07292 4.15625 6.84375C4.92708 7.59375 5.875 7.97917 7 8ZM7 1C7.85417 1.02083 8.5625 1.3125 9.125 1.875C9.6875 2.4375 9.97917 3.14583 10 4C9.97917 4.85417 9.6875 5.5625 9.125 6.125C8.5625 6.6875 7.85417 6.97917 7 7C6.14583 6.97917 5.4375 6.6875 4.875 6.125C4.3125 5.5625 4.02083 4.85417 4 4C4.02083 3.14583 4.3125 2.4375 4.875 1.875C5.4375 1.3125 6.14583 1.02083 7 1ZM8.59375 9.5H5.40625C3.88542 9.54167 2.61458 10.0729 1.59375 11.0938C0.572917 12.1146 0.0416667 13.3854 0 14.9062C0 15.2188 0.104167 15.4792 0.3125 15.6875C0.520833 15.8958 0.78125 16 1.09375 16H12.9062C13.2188 16 13.4792 15.8958 13.6875 15.6875C13.8958 15.4792 14 15.2188 14 14.9062C13.9583 13.3854 13.4271 12.1146 12.4062 11.0938C11.3854 10.0729 10.1146 9.54167 8.59375 9.5ZM12.9062 15H1.09375C1.03125 15 1 14.9688 1 14.9062C1.04167 13.6562 1.46875 12.6146 2.28125 11.7812C3.11458 10.9688 4.15625 10.5417 5.40625 10.5H8.59375C9.84375 10.5417 10.8854 10.9688 11.7188 11.7812C12.5312 12.6146 12.9583 13.6562 13 14.9062C13 14.9688 12.9688 15 12.9062 15Z"
                fill={props.color}
            />
        </svg>
    )
}
