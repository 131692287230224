import { memo } from "react";
import {
  PhoneIcon,
  EnvelopeIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import logo from "../../assets/logo.png";
import mountains from "../../assets/images/mountains.png";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkWrapper } from "../../../../apps/web/app/components/linkWrapper";
import useTranslate from "../../../../apps/web/app/hooks/useTranslate";

export const Footer = memo(() => {
  const { translate } = useTranslate();
  return (
    <div className="w-full bg-white">
      {/* <div className="mx-auto max-w-screen-xl bg-white">
                <div className="hidden justify-center bg-white xl:flex">
                    <img
                        className="pointer-events-none select-none"
                        src={mountains}
                        alt="img"
                        height={91}
                    />
                </div>
            </div> */}
      <div className="bg-black">
        <div className="text-textlight font-poppins 3xl:px-0 flex max-w-screen-xl flex-col items-center bg-black px-8 pb-[40px] pt-[50px] text-sm lg:mx-auto lg:flex-row lg:flex-wrap lg:items-end lg:justify-between lg:gap-10 lg:px-12 xl:justify-between xl:gap-32 2xl:gap-48">
          <div className="flex basis-auto flex-col items-center whitespace-nowrap lg:items-start">
            <img src={logo} width={156} alt="logo" />
            <p className="mt-3 font-semibold uppercase lg:mt-4">
              {translate("footerSchedule")}
            </p>
            <div className="mt-2 flex gap-4 lg:mt-4 lg:flex-col lg:gap-2">
              <div className="font-poppins flex items-center gap-1 font-light">
                <PhoneIcon className="text-neongreen w-5" />
                <span>
                  <a href="tel:+40752161351">+40 752 161 351</a>
                </span>
              </div>
              <div className="font-poppins flex items-center gap-1 font-light">
                <EnvelopeIcon className="text-neongreen w-5" />
                <span>
                  <a href="mailto:hello@letsridegraphics.com">
                    hello@letsridegraphics.com
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="flex w-full max-w-[350px] basis-full flex-col items-center lg:order-last lg:w-auto lg:flex-1 lg:items-start">
            <p className="mt-8 font-semibold">
              {translate("footerNewsletter")}
            </p>
            <input
              className="placeholder-textdark border-textdark mt-3 w-full border bg-transparent px-4 py-2 italic outline-none"
              type="text"
              placeholder={translate("footerInputPlaceholder")}
            ></input>
            <button className="bg-neongreen mt-5 flex w-full items-center px-4 py-1.5 text-base text-black">
              <span className="w-1/6"></span>
              <span className="w-2/3">{translate("subscribeButton")}</span>
              <span className="flex w-1/6 justify-end">
                <ArrowRightIcon className="w-4" />
              </span>
            </button>
          </div>
          <div className="mt-16 flex w-full max-w-screen-md justify-between text-[13px] lg:w-auto lg:basis-auto lg:gap-10 xl:flex-1">
            <ul className="flex flex-col justify-end leading-relaxed lg:leading-none">
              <LinkWrapper href="privacy-policy">
                <li className="lg:mt-[15px]">
                  {translate("footerPrivacyPolicy")}
                </li>
              </LinkWrapper>
              <LinkWrapper href="terms-and-conditions">
                <li className="lg:mt-[15px]">
                  {translate("footerTermsConditions")}
                </li>
              </LinkWrapper>
              <LinkWrapper href="shipping-and-return-policy">
                <li className="lg:mt-[15px]">
                  {translate("footerShippingReturnPolicy")}
                </li>
              </LinkWrapper>
              <LinkWrapper href="installation-guide">
                <li className="lg:mt-[15px]">
                  {translate("footerInstallationGuide")}
                </li>
              </LinkWrapper>
              <LinkWrapper href="contact">
                <li className="lg:mt-[15px]">{translate("footerContact")}</li>
              </LinkWrapper>
            </ul>
            {/* <ul className="flex flex-col justify-end leading-relaxed lg:leading-none">
                            <li className="lg:mt-[15px]">
                                Payment Information
                            </li>
                            <li className="lg:mt-[15px]">
                                Delivery Information
                            </li>
                            <LinkWrapper href="contact">
                                <li className="lg:mt-[15px]">Contact</li>
                            </LinkWrapper>
                            <LinkWrapper href="shipping-and-return-policy">
                                <li className="lg:mt-[15px]">
                                    Shipping & Return Policy
                                </li>
                            </LinkWrapper>
                            <li className="lg:mt-[15px]">Gift Voucher</li>
                        </ul> */}
          </div>
        </div>
        <div className="mx-auto max-w-screen-xl border-t border-t-[#181818]">
          <div className="flex max-w-screen-2xl flex-col items-center gap-5 bg-black py-8 lg:mx-auto lg:flex-row-reverse lg:justify-between">
            <div className="3xl:mr-0 flex justify-center gap-3 lg:mr-12">
              <a href="https://www.facebook.com/let5ride" target="_blank">
                <span style={{ maxWidth: 16, overflow: "hidden" }}>
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    className="text-neongreen h-4 text-base"
                  />
                </span>
              </a>
              <a href="https://instagram.com/letsridegraphics" target="_blank">
                <span style={{ maxWidth: 16, overflow: "hidden" }}>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-neongreen w-4 text-base"
                  />
                </span>
              </a>
            </div>
            <p className="font-poppins 3xl:ml-0 px-12 text-center text-xs leading-tight text-[#777777] lg:ml-12 lg:px-0">
              <span>{translate("footerDescription1")} </span>
              <LinkWrapper className="underline" href="terms-and-conditions">
                {translate("footerDescription2")}
              </LinkWrapper>{" "}
              <span>{translate("footerDescription3")} </span>
              <LinkWrapper className="underline" href="privacy-policy">
                {translate("footerDescription4")}
              </LinkWrapper>{" "}
              <span>{translate("footerDescription5")}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
