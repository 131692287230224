import {useParams} from '@remix-run/react';
import {useEffect, useState} from 'react';

const useTranslate = () => {
  const params = useParams();
  const [locale, setLocale] = useState(params.locale ?? 'en');

  useEffect(() => {
    setLocale(params.locale ?? 'en');
  }, [params]);

  const translate = (key: string) => {
    try {
      let translationFile;
      if (locale === 'en') {
        translationFile = require('public/locales/en/common.json');
        return translationFile[key];
      } else {
        translationFile = require('public/locales/ro/common.json');
        return translationFile[key];
      }
    } catch (error) {
      console.error(
        `Translation key "${key}" not found for locale "${locale}"`,
      );
      return key;
    }
  };

  const setLanguage = (language: string) => {
    setLocale(language);
  };

  return {translate, setLanguage};
};

export default useTranslate;
