import {Link, useParams} from '@remix-run/react';
import {memo} from 'react';

interface LinkWrapperProps {
  children: any;
  className?: any;
  href: string;
  key?: any;
}

export const LinkWrapper: React.FC<LinkWrapperProps> = memo(
  ({children, href, key, className}: LinkWrapperProps) => {
    const {locale} = useParams();

    return (
      <Link
        className={className}
        key={key}
        to={locale ? `/${locale}/${href}` : `/${href}`}
      >
        {children}
      </Link>
    );
  },
);

LinkWrapper.displayName = 'LinkWrapper';
