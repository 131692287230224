import { memo, useState } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import yamaha00101 from '../../assets/images/yamaha00101.png'
import kawasaki00102 from '../../assets/images/kawasaki001012.png'
import plasticKit from '../../assets/images/plastic-kit.png'
import saddleCover from '../../assets/images/saddle-cover.png'
import tShirt from '../../assets/images/t-shirt.png'

import { ShoppingCart } from '../../assets/svgs/ShoppingCart'
import { Heart } from '../../assets/svgs/Heart'
import { LinkWrapper } from '../../../../apps/web/app/components/linkWrapper'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const RecommendedProducts = memo(({ products }: { products?: any }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const { translate } = useTranslate()

    return (
        <div className="bg-white py-[53px] pb-[100px] lg:pb-48">
            <p className="font-montserrat text-center text-xl font-medium uppercase lg:mt-16 lg:text-3xl xl:text-[36px]">
                {translate('recommendedProducts')}
            </p>
            <p className="font-poppins text-textdark mt-5 px-2 text-center text-sm lg:mx-auto lg:mt-5 lg:w-[35%]">
                {translate('description')}
            </p>
            <Swiper
                className="mt-7 flex lg:mx-3 lg:mt-20"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={1}
                navigation
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                }}
                onSlideChange={(index) => setActiveIndex(index.activeIndex)}
            >
                {products.map((product: any, index: any) => (
                    <SwiperSlide key={index} className="relative px-2">
                        <LinkWrapper href={`products/${product.handle}`}>
                            <div className="relative flex justify-center px-5">
                                <img
                                    src={product.media.nodes[0].image.url}
                                    alt="motorcycle"
                                />
                            </div>
                            <div className="mx-6 mt-5 hidden md:block">
                                <div className="flex items-start">
                                    <div className="flex w-2/3 flex-col leading-tight">
                                        <span className="font-poppins text-sm font-semibold uppercase leading-tight">
                                            {product.title}
                                        </span>
                                        <span className="text-textdark text-sm">
                                            {product.variants.nodes[0].price
                                                .currencyCode +
                                                ' ' +
                                                product.variants.nodes[0].price
                                                    .amount}
                                        </span>
                                    </div>
                                    <div className="flex w-1/3 justify-end gap-3 text-[15px]">
                                        <div className="flex gap-3">
                                            {/* <button className="font-poppins border border-black p-1.5  ">
                                            <span className="text-white">
                                                <ShoppingCart className="" />
                                            </span>
                                        </button> */}
                                            <button className="font-poppins border border-black px-3 py-1.5  ">
                                                {translate('detailsButton')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LinkWrapper>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="mt-5 md:hidden">
                <div className="flex items-center">
                    <div className="flex w-2/3 flex-col leading-tight">
                        <span className="font-poppins text-sm font-semibold uppercase leading-tight">
                            {products[activeIndex]
                                ? products[activeIndex].title
                                : ''}
                        </span>
                        <span className="text-textdark text-sm">
                            {products[activeIndex]
                                ? products[activeIndex].variants.nodes[0].price
                                      .currencyCode +
                                  ' ' +
                                  products[activeIndex].variants.nodes[0].price
                                      .amount
                                : ''}
                        </span>
                    </div>
                    <div className="flex w-1/3 justify-end gap-3 text-[15px]">
                        <div className="flex gap-3">
                            {products[activeIndex] && (
                                <LinkWrapper
                                    href={`/products/${products[activeIndex].handle}`}
                                >
                                    <button className="font-poppins border border-black px-3 py-1.5">
                                        {translate('detailsButton')}
                                    </button>
                                </LinkWrapper>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
