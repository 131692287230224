import {memo} from 'react';
import {Video} from '@shopify/hydrogen';

export const VideoComponent = memo(
  ({
    className,
    src,
    autoPlay,
    loop,
    muted,
    width,
    height,
  }: {
    className?: string;
    src: string;
    autoPlay: boolean;
    loop: boolean;
    muted: boolean;
    width?: string;
    height?: string;
  }) => {
    return (
      <div className="relative">
        <video
          className="inset-0"
          playsInline={true}
          width={width}
          height={height}
          autoPlay={autoPlay}
          loop={loop}
          muted={muted}
        >
          <source src={src}></source>
        </video>
      </div>
    );
  },
);

VideoComponent.displayName = 'VideoComponent';
