import { useState, useRef, memo, RefObject, useEffect } from "react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";

import font1 from "../../../assets/images/style1.png";
import font2 from "../../../assets/images/style2.png";
import font3 from "../../../assets/images/style3.png";
import font4 from "../../../assets/images/style4.png";
import font5 from "../../../assets/images/style5.png";
import font6 from "../../../assets/images/style6.png";
import font7 from "../../../assets/images/style7.png";
import font8 from "../../../assets/images/style8.png";
import font9 from "../../../assets/images/style9.png";
import font10 from "../../../assets/images/style10.png";
import useTranslate from "../../../../../apps/web/app/hooks/useTranslate";

const logos = [
  {
    image: font1,
    title: "Style 1",
    value: "Style 1",
  },
  {
    image: font2,
    title: "Style 2",
    value: "Style 2",
  },
  {
    image: font3,
    title: "Style 3",
    value: "Style 3",
  },
  {
    image: font4,
    title: "Style 4",
    value: "Style 4",
  },
  {
    image: font5,
    title: "Style 5",
    value: "Style 5",
  },
  {
    image: font6,
    title: "Style 6",
    value: "Style 6",
  },
  {
    image: font7,
    title: "Style 7",
    value: "Style 7",
  },
  {
    image: font8,
    title: "Style 8",
    value: "Style 8",
  },
  {
    image: font9,
    title: "Style 9",
    value: "Style 9",
  },
  {
    image: font10,
    title: "Style 10",
    value: "Style 10",
  },
];

export const FontSelect = memo(
  ({
    className,
    type,
    label,
    required,
    value,
    onSelect,
  }: {
    className?: String;
    type: String;
    label?: String;
    required: boolean;
    value?: String;
    onSelect?: any;
  }) => {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    const changeRef: RefObject<HTMLSelectElement> =
      useRef<HTMLSelectElement>(null);

    const handleOptionClick = (value: string) => {
      if (changeRef.current !== null) {
        changeRef.current.value = value;
        setSelectedValue(changeRef.current.value);
        onSelect(changeRef.current.value);
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
    }, []);

    const selectRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: any) => {
      if (selectRef.current) {
        if (!selectRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    const { translate } = useTranslate();

    return (
      <div ref={selectRef} className={`font-poppins mb-5 ${className}`}>
        <p className="w-2/3">
          {label}
          <span className="font-bold text-red-500">{required ? " *" : ""}</span>
        </p>
        <div
          onClick={() => setOpen((prev) => !prev)}
          className="text-textdarks mt-1.5 flex cursor-pointer items-center justify-between bg-[#f0f0f0] py-2 pl-5 pr-2"
        >
          <span className={`${selectedValue ? "" : "italic"}`}>
            {selectedValue ? selectedValue : translate("choose")}
          </span>
          <ChevronUpIcon
            className={`w-4 ${
              open ? "transition-transform" : "-rotate-180 transition-transform"
            }`}
          />
        </div>
        {type === "image" && (
          <>
            <div
              className={`mt-1 grid-cols-2 gap-5 bg-gray-50 px-10 py-4 drop-shadow-md transition-transform ${
                open ? "grid md:flex" : "hidden"
              }`}
            >
              {logos.map((logo, index) => (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOptionClick(logo.value);
                    setOpen((prev) => !prev);
                  }}
                  className="flex cursor-pointer flex-col items-center gap-3 py-4 hover:bg-sky-200"
                  key={index}
                >
                  <img width={113} src={logo.image} alt="img" />
                  <span>{logo.title}</span>
                </div>
              ))}
            </div>
            <select className="hidden" ref={changeRef} name="cars" id="cars">
              <option value="Style 1">Option 1</option>
              <option value="Style 2">Option 2</option>
              <option value="Style 3">Option 3</option>
              <option value="Style 4">Option 4</option>
              <option value="Style 5">Option 5</option>
              <option value="Style 6">Option 6</option>
              <option value="Style 7">Option 7</option>
              <option value="Style 8">Option 8</option>
              <option value="Style 9">Option 9</option>
              <option value="Style 10">Option 10</option>
            </select>
          </>
        )}
      </div>
    );
  }
);
