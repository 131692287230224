import { ImageComponent } from '../../../../apps/web/app/components/image'
import { LinkWrapper } from '../../../../apps/web/app/components/linkWrapper'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'
import mxGraphics from '../../assets/grafice.webp'

export const MxGraphics = () => {
    const { translate } = useTranslate()

    return (
        <div className="font-poppins relative bg-black text-white ">
            <div>
                <img className="lg:w-2/3" src={mxGraphics} alt="img" />
            </div>
            <div className="absolute bottom-4 left-4 flex w-full text-left lg:left-auto lg:right-16 lg:top-1/2 lg:flex lg:w-[30%] lg:-translate-y-1/2 lg:flex-col lg:justify-center lg:gap-4 lg:text-left xl:gap-6 2xl:right-32 2xl:gap-8">
                <div>
                    <h2 className="text-xl font-medium uppercase lg:text-3xl xl:text-4xl 2xl:text-5xl">
                        <span className="hidden lg:inline">
                            {translate('topQuality')}
                        </span>{' '}
                        <br className="hidden lg:block" />
                        <span className="text-lg font-bold lg:text-4xl xl:text-5xl 2xl:text-6xl">
                            {translate('mxGraphics')}
                        </span>{' '}
                        <span className="hidden lg:inline">
                            {translate('fullKits')}
                        </span>
                    </h2>
                    <div className="hidden lg:block lg:text-sm">
                        <button className="bg-neongreen font-montserrat bottom-[10px] right-0 flex items-center gap-2 py-1.5 pl-7 pr-4 text-[15px] text-black lg:mt-2 lg:text-base xl:mt-5">
                            <LinkWrapper href="mx-graphics">
                                <span>{translate('desktopCTA')}</span>
                            </LinkWrapper>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-4 w-4"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <button className="bg-neongreen font-montserrat absolute bottom-[10px] right-0 flex items-center gap-2 py-1.5 pl-5 pr-2 text-[15px] text-black lg:hidden">
                <LinkWrapper href="mx-graphics">
                    <span>{translate('mobileCTA')}</span>
                </LinkWrapper>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-4 w-4"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                </svg>
            </button>
        </div>
    )
}
