import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const PrivacyPolicyComponent = () => {
    const { translate } = useTranslate()

    return (
        <div className="bg-white">
            <div className="font-poppins text-textdark mx-auto max-w-screen-xl bg-white px-8 py-12 text-sm font-[400] lg:py-16">
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('privacyPolicyHeadline1')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP1')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP2')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP3')}
                    </p>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('privacyPolicyHeadline2')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP4')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP5')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP6')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('privacyPolicyHeadline3')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP7')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP8')}
                        <span>
                            <a
                                className="mr-4 font-semibold underline"
                                href="https://policies.google.com/technologies/partner-sites"
                                target="_blank"
                            >
                                https://policies.google.com/technologies/partner-sites.
                            </a>
                        </span>
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP9')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP10')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('privacyPolicyHeadline4')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP11')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('privacyPolicyP12')}
                    </p>
                </div>
            </div>
        </div>
    )
}
