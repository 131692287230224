export const DisclaimerComponent = () => {
    return (
        <div className="bg-white">
            <div className="font-poppins text-textdark mx-auto max-w-screen-xl bg-white px-8 py-12 text-sm font-[400] lg:py-16">
                <p className="mt-5 font-light">
                    The information is provided by Let’s ride RL and while we
                    endeavor to keep the information up to date and correct, we
                    make no representations or warranties of any kind, express
                    or implied, about the completeness, accuracy, reliability,
                    suitability or availability with respect to the website or
                    the information, products, services, or related graphics
                    contained on the website for any purpose. Any reliance you
                    place on such information is therefore strictly at your own
                    risk.
                </p>
                <p className="mt-5 font-light">
                    In no event will we be liable for any loss or damage
                    including without limitation, indirect or consequential loss
                    or damage, or any loss or damage whatsoever arising from
                    loss of data or profits arising out of, or in connection
                    with, the use of this website.
                </p>
                <p className="mt-5 font-light">
                    Through this website you are able to link to other websites
                    which are not under the control of Let’s Ride SRL.
                </p>
                <p className="mt-5 font-light">
                    We have no control over the nature, content and availability
                    of those sites. The inclusion of any links does not
                    necessarily imply a recommendation or endorse the views
                    expressed within them.
                </p>
                <p className="mt-5 font-light">
                    Every effort is made to keep the website up and running
                    smoothly. However, Let’s Ride SRL takes no responsibility
                    for, and will not be liable for, the website being
                    temporarily unavailable due to technical issues beyond our
                    control.
                </p>
            </div>
        </div>
    )
}
