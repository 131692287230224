import { memo, useState } from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import useTranslate from "../../../../apps/web/app/hooks/useTranslate";

interface IconProps {
  id: number;
  open: number;
}

function Icon({ id, open }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const ProductHeader = memo(
  ({ product, language }: { product: any; language: string }) => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    };

    let metafield = {
      title: "",
      value: "",
      sections: [],
    };
    let pairs;
    if (product.metafields.length) {
      metafield = JSON.parse(
        language === "ro"
          ? product.metafields[1].value
          : product.metafields[0].value
      );

      //@ts-ignore
      const inputArray = metafield?.sections[0].list;

      const pairLength = Math.floor(inputArray.length / 4) + 1;
      pairs = inputArray.reduce((result: any, element: any, index: any) => {
        const pairIndex = Math.floor(index / pairLength);

        if (!result[pairIndex]) {
          result[pairIndex] = [];
        }

        result[pairIndex].push(element);

        return result;
      }, []);
    }

    const { translate } = useTranslate();

    return (
      <div className="font-poppins bg-textlight mt-[124.5px] flex flex-col items-center px-8 py-10">
        <h1 className="font-montserrat text-center text-xl font-medium uppercase lg:mt-14 lg:text-4xl">
          {product.title}
        </h1>
        <p className="mt-2 text-center text-sm font-light lg:text-base">
          *{translate("adapted")}
        </p>
        <div>
          <Accordion
            className="mt-4 flex flex-col items-start justify-center lg:items-center"
            open={open === 1}
            icon={<Icon id={1} open={open} />}
          >
            <div className="flex w-full justify-center lg:mt-3">
              <AccordionHeader
                className="font-poppins w-[170px] whitespace-nowrap border border-black px-4 py-2 text-sm font-[500]"
                onClick={() => handleOpen(1)}
              >
                <span className="text-black">
                  {translate("productDetails")}
                </span>
              </AccordionHeader>
            </div>
            <AccordionBody className="font-poppins mt-10 w-full max-w-screen-2xl text-black lg:mb-12 lg:bg-white lg:px-[55px] lg:pb-[75px] lg:pt-[50px]">
              <div>
                <p className="font-montserrat border-textlight border-b-2 text-base font-medium uppercase text-black lg:pb-4 lg:text-center lg:text-lg lg:font-semibold">
                  {metafield?.title}
                </p>
                <ul className="mt-10 lg:mr-8 lg:flex lg:items-start lg:justify-start lg:gap-20 xl:mr-20 xl:gap-40">
                  {pairs?.map((pair: any, index: any) => (
                    <div key={index}>
                      {pair.map((element: any, secondIndex: any) => (
                        <li
                          key={secondIndex}
                          className="my-2 flex items-start gap-4"
                        >
                          <CheckIcon className="w-5" />
                          <span>{element.value}</span>
                        </li>
                      ))}
                    </div>
                  ))}
                </ul>
                {product.handle === "custom-design" && (
                  <>
                    <p className="my-5 text-lg font-bold">
                      {metafield?.sections[3].title}
                    </p>
                    <p className="text-lg">
                      {metafield?.sections[3].description}
                    </p>
                  </>
                )}
                <p
                  className={`mb-5 ${
                    product.handle !== "custom-design" ? "mt-10" : "mt-5"
                  } font-bold`}
                >
                  {metafield?.sections[1].title}
                </p>
                <p>{metafield?.sections[1].description}</p>
                <p className="my-5 font-bold">{metafield?.sections[2].title}</p>
                <p>{metafield?.sections[2].description}</p>
                {product.handle !== "custom-design" && (
                  <>
                    <p className="my-5 font-bold">
                      {metafield?.sections[3].title}
                    </p>
                    <p>{metafield?.sections[3].description}</p>
                  </>
                )}
                {metafield.footerBullet1 && (
                  <>
                    <p className="my-5 font-bold">{metafield?.footer}</p>
                    <ul className="ml-10 list-disc">
                      <li>{metafield?.footerBullet1}</li>
                      <li>{metafield?.footerBullet2}</li>
                      {/* <li>{metafield?.footerBullet3}</li> */}
                    </ul>
                  </>
                )}
              </div>
            </AccordionBody>
          </Accordion>
        </div>
      </div>
    );
  }
);
