import { memo } from 'react'
import { ImageComponent } from '../../../../apps/web/app/components/image'
import map from '../../assets/images/map.png'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const ContactForm = memo(() => {
    const { translate } = useTranslate()

    return <></>
})
