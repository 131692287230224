import { memo } from 'react'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const ShippingReturnPolicyComponent = memo(() => {
    const { translate } = useTranslate()

    return (
        <div className="bg-white">
            <div className="font-poppins text-textdark mx-auto max-w-screen-xl bg-white px-8 py-12 text-sm font-[400] lg:py-16">
                <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                    {translate('shippingHeadline1')}
                </p>
                <p className="mt-5 font-light">{translate('shippingP1')}</p>
                <p className="mt-5 font-light">{translate('shippingP2')}</p>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('shippingHeadline2')}
                    </p>
                    <p className="mt-5 font-light">{translate('shippingP3')}</p>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('shippingHeadline3')}
                    </p>
                    <p className="mt-5 font-light">{translate('shippingP4')}</p>
                    <p className="mt-5 font-light">{translate('shippingP5')}</p>
                    <p className="mt-5 font-light">
                        <span className="font-semibold">
                            {translate('shippingP61')}
                        </span>
                        {' ' + translate('shippingP62') + ' '}
                        <span className="font-semibold">
                            {translate('shippingP63')}
                        </span>
                    </p>
                    <p className="mt-5 font-light">
                        <span className="font-semibold">
                            {translate('shippingP71')}
                        </span>
                        {' ' + translate('shippingP72') + ' '}
                        <span className="font-semibold">
                            {translate('shippingP73') + ' '}
                        </span>
                        {translate('shippingP74')}
                    </p>
                    <p className="mt-5 font-light">{translate('shippingP8')}</p>
                    <p className="mt-5 font-light">{translate('shippingP9')}</p>
                    <p className="mt-5 font-light">
                        {translate('shippingP10') + ': '}
                        <span>
                            <a
                                className="font-semibold underline"
                                href="mailto:hello@letsridegraphics.com"
                            >
                                hello@letsridegraphics.com
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
})
