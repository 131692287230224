import Uppy, {UppyFile} from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import {Dashboard} from '@uppy/react';
//@ts-ignore
import uppyCore from '@uppy/core/dist/style.css';
//@ts-ignore
import uppyDashboard from '@uppy/dashboard/dist/style.min.css';
//@ts-ignore
import uppyFileInput from '@uppy/file-input/dist/style.css';
//@ts-ignore
import uppyProgressBar from '@uppy/progress-bar/dist/style.css';
import {LinksFunction} from '@shopify/remix-oxygen';

import {getSignedUrl} from '@aws-sdk/s3-request-presigner';
import {useLoaderData} from '@remix-run/react';
import {useEffect, useState} from 'react';

export const links: LinksFunction = () => [
  {rel: 'stylesheet', href: uppyCore},
  {rel: 'stylesheet', href: uppyDashboard},
  {rel: 'stylesheet', href: uppyFileInput},
  {rel: 'stylesheet', href: uppyProgressBar},
];

export const Upload = ({
  uppy,
  className,
  height,
  width,
  onUrlsChange,
}: {
  uppy: Uppy;
  className?: string;
  height?: any;
  width?: any;
  onUrlsChange?: any;
}) => {
  const {name} = useLoaderData();

  const [urls, setUrls] = useState<Set<string>>(new Set());

  useEffect(() => {
    onUrlsChange(urls);
  }, [urls]);

  return (
    <>
      <Dashboard
        uppy={uppy}
        hideUploadButton
        width={450}
        onSubmit={(e) => console.log(e)}
        height={parseInt(height)}
        className={`${className} font-poppins`}
        id="1"
      />
    </>
  );
};
