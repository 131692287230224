import { memo } from 'react'
import { VideoComponent } from '../../../../apps/web/app/components/video'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const Hero = memo(() => {
    const { translate } = useTranslate()

    return (
        <div className="relative mt-[104px] flex max-h-[100vh] flex-col items-center bg-black tracking-wide md:mt-0">
            <VideoComponent
                width={'100%'}
                className="md:h-screen"
                src={'/assets/videos/hero.mp4'}
                autoPlay={true}
                loop={true}
                muted={true}
            />
            <h1 className="absolute bottom-[13%] w-full select-none text-center text-2xl font-bold uppercase tracking-tight text-white md:bottom-[11%] md:left-16 md:w-[70%] md:text-left md:text-5xl md:font-medium lg:bottom-[15%] lg:w-[60%] lg:text-6xl xl:bottom-[15%] xl:w-1/2 xl:text-7xl 2xl:w-[48%]">
                {translate('heroTitle1')}
                <span className="text-neongreen">
                    {' ' + translate('heroTitle2') + ' '}
                </span>
                {translate('heroTitle3')}
            </h1>
        </div>
    )
})
