import React, { memo, useState } from "react";
import {
  PhoneIcon,
  EnvelopeIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Badge,
  Button,
  Drawer,
} from "@material-tailwind/react";
import { ImageComponent } from "../../../../apps/web/app/components/image";
import { LinkWrapper } from "../../../../apps/web/app/components/linkWrapper";
import logo from "../../assets/logo.png";
import { ShoppingCart } from "../../assets/svgs/ShoppingCart";
import { ShoppingCartIcon } from "../../assets/svgs/ShoppingCartIcon";
import { HeartIcon } from "../../assets/svgs/HeartIcon";
import { UserIcon } from "../../assets/svgs/UserIcon";
import useTranslate from "../../../../apps/web/app/hooks/useTranslate";
import { LanguageSwitcher } from "../../../../apps/web/app/components/languageSwitcher";
import romanianFlag from "../../../../apps/web/public/assets/images/romanian.png";
import { Gallery } from "../gallery";

const width = typeof window !== "undefined" ? window.innerWidth : 0;

interface IconProps {
  id: number;
  open: number;
}

function Icon({ id, open }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export const Navbar = memo(({ cartQuantity }: any) => {
  const [open, setOpen] = useState(false);
  const [accOpen, setAccOpen] = useState(0);

  const { translate } = useTranslate();

  const handleOpen = (value: any) => {
    setAccOpen(accOpen === value ? 0 : value);
  };

  return (
    <header className="fixed top-0 z-[9999] w-screen">
      <div className="font-poppins flex items-center justify-between border-b border-b-gray-900 bg-black px-6 py-2 font-light text-white">
        <div className="flex w-full items-center justify-between gap-4 lg:w-1/3 lg:justify-start">
          <div className="flex items-center gap-2 whitespace-nowrap text-[13px] lg:text-sm">
            <PhoneIcon
              style={{
                maxWidth: "16px",
                maxHeight: "16px",
                width: "16px",
              }}
              className="text-neongreen w-4"
            />
            <span className="text-xs">
              <a href="tel:+40752161351">+40 752 161 351</a>
            </span>
          </div>
          <div className="rlg:text-sm flex items-center gap-2 text-[13px]">
            <EnvelopeIcon
              style={{
                maxWidth: "16px",
                maxHeight: "16px",
                width: "16px",
              }}
              className="text-neongreen"
            />
            <span className="text-xs">
              <a href="mailto:hello@letsridegraphics.com">
                hello@letsridegraphics.com
              </a>
            </span>
          </div>
        </div>
        <div className="hidden w-1/3 justify-center lg:flex">
          <button className="bg-neongreen flex items-center gap-6 justify-self-center py-[5px] pl-[52px] pr-[13px] text-black">
            <span>{translate("subscribeButton")}</span>
            <ArrowRightIcon className="w-4" />
          </button>
        </div>
        <div className="text-neongreen hidden w-1/3 items-center justify-end gap-4 lg:flex">
          {/* <img src={romanianFlag} /> */}
          <LanguageSwitcher />
          {/* <a
                        className="mt-1"
                        href="https://www.facebook.com/let5ride"
                        target="_blank"
                    >
                        <FontAwesomeIcon
                            icon={faFacebookF}
                            className="h-4 text-lg"
                        />
                    </a>
                    <a
                        className="mt-1"
                        href="https://www.instagram.com/letsride.ro/"
                        target="_blank"
                    >
                        <FontAwesomeIcon
                            icon={faInstagram}
                            className="w-4 text-lg"
                        />
                    </a> */}
        </div>
      </div>
      <div className="font-open-sans flex items-center justify-between bg-black px-6 py-5 font-light text-white">
        <div className="flex w-1/3 items-center justify-start lg:hidden">
          <span
            className="inline max-w-[16px] text-xl"
            onClick={() => setOpen(true)}
          >
            <svg
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </span>{" "}
          <Drawer
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            size={1024}
            className="flex flex-col uppercase"
          >
            <div className="flex items-center bg-black px-7 pb-[27px] pt-[15px]">
              <div className="w-1/6"></div>
              <div
                onClick={() => {
                  setOpen(false);
                }}
                className="flex w-2/3 justify-center"
              >
                <LinkWrapper href="">
                  <img src={logo} width={156} alt="logo" />
                </LinkWrapper>
              </div>
              <div className="mt-[13px] flex w-1/6 justify-end">
                <span
                  className="tracking-none flex w-6 justify-center rounded border border-white text-base font-semibold leading-none"
                  onClick={() => setOpen(false)}
                >
                  &#215;
                </span>
              </div>
            </div>
            <div className="flex flex-1 flex-col justify-between pb-[76px] pt-[25px]">
              <div>
                <div className="px-[65px]">
                  <ul className="font-[400] text-black">
                    <LinkWrapper href="products/custom-design">
                      <li
                        onClick={() => {
                          setOpen(false);
                        }}
                        className={`border-b-1 border-b-bordergray border-t-1 border-t-bordergray border-b border-t py-2 leading-none`}
                      >
                        {translate("customGraphics")}
                      </li>
                    </LinkWrapper>
                  </ul>
                </div>
                <Accordion
                  className="px-[65px] uppercase"
                  open={accOpen === 1}
                  icon={<Icon id={1} open={accOpen} />}
                >
                  <AccordionHeader
                    className="font-poppins border-none py-1 text-base font-[300] uppercase text-black"
                    onClick={() => handleOpen(1)}
                  >
                    <LinkWrapper href="mx-graphics">
                      <span
                        className="font-[400] text-black"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {translate("mxGraphics")}
                      </span>
                    </LinkWrapper>
                  </AccordionHeader>
                  <AccordionBody className="ml-[20px] mt-0 text-base text-black">
                    <ul className="font-[300]">
                      <LinkWrapper href="mx-graphics/yamaha">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="mb-1.5 mt-0"
                        >
                          Yamaha
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/suzuki">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          Suzuki
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/ktm">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          KTM
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/kawasaki">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          Kawasaki
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/husqvarna">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          Husqvarna
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/honda">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          Honda
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/beta">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          Beta
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/sherco">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          Sherco
                        </li>
                      </LinkWrapper>
                      <LinkWrapper href="mx-graphics/gasgas">
                        <li
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="my-1.5"
                        >
                          GasGas
                        </li>
                      </LinkWrapper>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <div className="px-[65px]">
                  <ul className="font-[400] text-black">
                    <LinkWrapper href="seat-covers">
                      <li
                        onClick={() => {
                          setOpen(false);
                        }}
                        className={`border-b-1 border-t-bordergray border-b-bordergray border-b border-t py-2 leading-none`}
                      >
                        {translate("seatCovers")}
                      </li>
                    </LinkWrapper>
                    <LinkWrapper href="apparel">
                      <li
                        onClick={() => {
                          setOpen(false);
                        }}
                        className={`border-b-1 border-b-bordergray py-2 leading-none`}
                      >
                        {translate("apparel")}
                      </li>
                    </LinkWrapper>
                    <LinkWrapper href="accessories">
                      <li
                        onClick={() => {
                          setOpen(false);
                        }}
                        className={`border-b-1 border-b-bordergray border-t-1 border-t-bordergray border-b border-t py-2 leading-none`}
                      >
                        {translate("accessories")}
                      </li>
                    </LinkWrapper>
                    <li
                      onClick={() => {
                        setOpen(false);
                      }}
                      className="border-b-5 border-b-bordergray border-b py-2 leading-none"
                    >
                      <LinkWrapper href="contact">Contact</LinkWrapper>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex items-center justify-center gap-3">
                <LanguageSwitcher />
              </div>
            </div>
          </Drawer>
        </div>
        <div className="hidden w-1/6 lg:block">
          <LinkWrapper href="">
            <img src={logo} width={156} alt="logo" />
          </LinkWrapper>
        </div>
        <div className="hidden w-2/3 justify-center lg:flex">
          <ul className="flex gap-8 uppercase tracking-wider md:gap-8 lg:text-sm xl:gap-12 xl:text-[15px]">
            <LinkWrapper href="products/custom-design">
              <li
                onClick={() => {
                  setOpen(false);
                }}
                className="hover:text-neongreen cursor-pointer"
              >
                {translate("customGraphics")}
              </li>
            </LinkWrapper>
            <LinkWrapper href="mx-graphics">
              <li
                onClick={() => {
                  setOpen(false);
                }}
                className="hover:text-neongreen cursor-pointer"
              >
                {translate("mxGraphics")}
              </li>
            </LinkWrapper>
            <LinkWrapper href="seat-covers">
              <li
                onClick={() => {
                  setOpen(false);
                }}
                className="hover:text-neongreen cursor-pointer"
              >
                {translate("seatCovers")}
              </li>
            </LinkWrapper>
            <LinkWrapper href="apparel">
              <li
                onClick={() => {
                  setOpen(false);
                }}
                className="hover:text-neongreen cursor-pointer"
              >
                {translate("apparel")}
              </li>
            </LinkWrapper>
            <LinkWrapper href="accessories">
              <li
                onClick={() => {
                  setOpen(false);
                }}
                className="hover:text-neongreen cursor-pointer"
              >
                {translate("accessories")}
              </li>
            </LinkWrapper>

            <LinkWrapper href="contact">
              <li
                onClick={() => {
                  setOpen(false);
                }}
                className="hover:text-neongreen cursor-pointer"
              >
                Contact
              </li>
            </LinkWrapper>
          </ul>
        </div>
        <div className="flex w-1/3 justify-center lg:hidden">
          <button className="xxs:px-2 xs:px-5 bg-neongreen xs:gap-1 font-base flex flex-row items-center justify-between py-1.5 text-black  sm:gap-2">
            <span className="font-poppins whitespace-nowrap font-light">
              {translate("subscribeButton")}
            </span>
            <ArrowRightIcon className="w-4" />
          </button>
        </div>
        <div className="flex w-1/3 items-end justify-end gap-5 pt-1 lg:w-1/6 lg:gap-5">
          <span className="relative scale-[1.15]">
            <LinkWrapper href="cart">
              <ShoppingCartIcon color="white" />
            </LinkWrapper>
            {cartQuantity > 0 && (
              <span
                className={`bg-neongreen absolute right-0 top-0 flex aspect-square h-4 w-4 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full font-semibold text-black ${
                  cartQuantity.toString().length > 1 ? "text-[10px]" : "text-xs"
                }`}
              >
                {cartQuantity}
              </span>
            )}
          </span>
          <span className="scale-[1.15]">
            <LinkWrapper href="account">
              <UserIcon color="white" />
            </LinkWrapper>
          </span>
        </div>
      </div>
    </header>
  );
});
