import { memo } from 'react'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const TermsConditions = memo(() => {
    const { translate } = useTranslate()

    return (
        <div className="bg-white">
            <div className="font-poppins text-textdark mx-auto max-w-screen-xl bg-white px-8 py-12 text-sm font-[400] lg:py-16">
                <p className="mt-5 font-light">
                    {translate('termsConditionsP1')}
                </p>
                <p className="mt-5 font-light">
                    {translate('termsConditionsP2')}
                </p>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline1')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP3')}
                    </p>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline2')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP4')}
                    </p>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline3')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP5')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP6')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP7')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP8')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP9')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP10')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline4')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP11')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP12')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP13')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline5')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP14')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP15')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP16')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP17')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline6')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP18')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP19')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline7')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP20')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP21')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP22')}
                    </p>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline8')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP23')}
                    </p>
                    <ul className="mt-5 list-disc pl-4 font-light">
                        <li className="pb-4">
                            {translate('termsConditionsP24')}
                        </li>
                        <li className="pb-4">
                            {translate('termsConditionsP25')}
                        </li>
                        <li className="pb-4">
                            {translate('termsConditionsP26')}
                        </li>
                    </ul>
                </div>
                <div>
                    <p className="font-montserrat mt-8 border-b-2 border-[#f0f0f0] pb-2 text-base font-semibold uppercase text-black">
                        {translate('termsConditionsHeadline9')}
                    </p>
                    <p className="mt-5 font-light">
                        {translate('termsConditionsP27')}
                    </p>
                </div>
            </div>
        </div>
    )
})
