import { memo, useEffect, useState } from 'react'

import { LinkWrapper } from '../../../../apps/web/app/components/linkWrapper'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

export const RecentlyAdded = ({
    products,
    area,
}: {
    products: any
    area: string
}) => {
    const { translate } = useTranslate()

    return (
        <div className="bg-white px-8 py-12">
            <p className="font-montserrat border-b-[3px] border-black py-1.5 font-medium uppercase">
                {translate('recentlyAdded')}
            </p>
            {products.length > 1 && (
                <ul className="font-poppins mt-2 text-[15px]">
                    {products.slice(0, 5).map((recent: any, index: any) => (
                        <div key={index}>
                            <LinkWrapper href={`products/${recent.handle}`}>
                                <li className="flex gap-8 border-b-2 border-[#f0f0f0] py-3">
                                    <img
                                        src={recent.media.nodes[0].image.url}
                                        alt="img"
                                        width={100}
                                    />
                                    <div className="flex flex-col">
                                        <span className="font-semibold leading-tight">
                                            {recent.title}
                                        </span>
                                        <span className="text-textdark mt-2 text-sm leading-none">
                                            {recent.variants.nodes[0].price
                                                .currencyCode +
                                                ' ' +
                                                recent.variants.nodes[0].price
                                                    .amount}
                                        </span>
                                    </div>
                                </li>
                            </LinkWrapper>
                        </div>
                    ))}
                </ul>
            )}
        </div>
    )
}
