export const generateRandomNumber = (maxValue: number): number => {
  if (maxValue < 1) {
    return 0;
  }
  const randomNumber = Math.floor(Math.random() * maxValue) + 1;
  return randomNumber;
};

/**
 * Calculates the linear index of a selected combination of indexes in a multi-dimensional array.
 *
 * @param selectedIndexes - Array of selected indexes for each variant.
 * @param options - Array of the number of options for each variant.
 * @returns The linear index in the flattened array.
 */
export const calculateLinearIndex = <T extends number[]>(
  selectedIndexes: T,
  options: T,
): number => {
  let linearIndex = 0;
  let product = 1; // Keeps track of the product of sizes for subsequent dimensions.

  for (let i = selectedIndexes.length - 1; i >= 0; i--) {
    linearIndex += selectedIndexes[i] * product;
    product *= options[i]; // Update product for the next dimension.
  }

  return linearIndex;
};
