import { memo, useEffect, useState } from "react";

import yamaha from "../../assets/images/scooters/yamaha.png";
import suzuki from "../../assets/images/scooters/suzuki.png";
import ktm from "../../assets/images/scooters/ktm.png";
import kawasaki from "../../assets/images/scooters/kawasaki.png";
import husquvarna from "../../assets/images/scooters/husquvarna.png";
import honda from "../../assets/images/scooters/honda.png";
import beta from "../../assets/images/scooters/beta.png";
import { Producers } from "../producers";
import { RecentlyAdded } from "../recentlyAdded";
import { LinkWrapper } from "../../../../apps/web/app/components/linkWrapper";
import { Empty } from "antd";
import useTranslate from "../../../../apps/web/app/hooks/useTranslate";
import { ProductCard } from "./productCard";

const categories = [
  { image: yamaha, name: "yamaha" },
  { image: suzuki, name: "suzuki" },
  { image: ktm, name: "ktm" },
  { image: kawasaki, name: "kawasaki" },
  { image: husquvarna, name: "husquvarna" },
  { image: honda, name: "honda" },
  { image: beta, name: "beta" },
];

export const Category = memo(
  ({
    collections,
    products,
    handle,
    area,
  }: {
    collections: any;
    products: any;
    handle?: any;
    area?: any;
  }) => {
    const [empty, setEmpty] = useState<boolean>(true);

    useEffect(() => {
      setEmpty(true);
      if (handle) {
        products.map((product: any) => {
          if (product.tags.includes(area) && product.tags.includes(handle)) {
            setEmpty(false);
            return;
          }
        });
      }
    }, [products, handle, area]);

    const { translate } = useTranslate();

    return (
      <div className="bg-white md:pb-32 md:pt-20">
        <div className="relative mx-auto flex max-w-[1536px] flex-col md:flex-row lg:px-4">
          <div className="sticky left-0 top-0 hidden h-fit max-w-screen-md self-start md:block md:w-[350px] md:min-w-[350px] md:self-start lg:w-[416px] lg:min-w-[416px]">
            <div>
              <Producers
                area={area}
                collections={collections}
                handle={handle ? handle : undefined}
              />
              <RecentlyAdded area={area} products={products} />
            </div>
          </div>
          <div>
            <div>
              <p className="text-textdark ml-8 mt-8 hidden text-sm uppercase md:block">
                <LinkWrapper href={`${area}`}>
                  {area === "mx-graphics" ? translate("mxGraphics") : ""}
                  {area === "seat-covers" ? translate("seatCovers") : ""}
                  {area === "apparel" ? translate("apparel") : ""}
                  {area === "accessories" ? translate("accessories") : ""}
                </LinkWrapper>
                {handle
                  ? " / " +
                    (handle === "hoodies" ||
                    handle === "caps" ||
                    handle === "t-shirts"
                      ? translate(handle)
                      : handle)
                  : ""}
              </p>
              {!handle && (
                <div
                  className={`hidden grid-cols-1 md:grid lg:px-8 lg:pb-16 xl:grid-cols-2`}
                >
                  {products.map(
                    (product: any, index: any) =>
                      product.tags.includes(area) && (
                        <ProductCard
                          key={index}
                          index={index}
                          product={product}
                          className={`font-poppins flex flex-col bg-white px-8 pt-12 ${
                            index === categories.length - 1 ? "pb-16" : ""
                          }`}
                        />
                      )
                  )}
                </div>
              )}
              <div
                className={`${
                  handle ? "" : "hidden"
                } grid-cols-1 md:grid lg:px-8 lg:pb-16 xl:grid-cols-2`}
              >
                {handle &&
                  products.map(
                    (product: any, index: any) =>
                      product.tags.includes(area) &&
                      product.tags.includes(handle) && (
                        <ProductCard
                          key={index}
                          index={index}
                          product={product}
                          className={`font-poppins flex flex-col bg-white px-8 pt-12 ${
                            index === categories.length - 1 ? "pb-16" : ""
                          }`}
                        />
                      )
                  )}
              </div>
              {empty && handle && (
                <Empty
                  description="No products available for this category"
                  className="font-poppins mt-10 text-sm md:mt-0"
                />
              )}
              {handle && (
                <div className="mt-6 block md:hidden">
                  <div className="mx-8 flex flex-col items-center border-b-2 border-b-[#f0f0f0]">
                    <p className="text-textdark mt-8 block text-lg font-semibold uppercase">
                      {area === "mx-graphics" &&
                        translate("compatibleGraphics")}
                      {area === "seat-covers" && translate("seatCovers")}
                      {area === "apparel" && translate("similarApparel")}
                      {area === "accessories" &&
                        translate("similarAccessories")}
                    </p>
                    <p className="pb-10 text-center text-sm">
                      {translate("description")}
                    </p>
                  </div>
                  <div
                    className={`${
                      handle ? "" : "hidden"
                    } grid-cols-1 pb-16 md:grid lg:px-8 xl:grid-cols-2`}
                  >
                    {products.map((product: any, index: any) => {
                      if (
                        product.tags.includes(area) &&
                        !product.tags.includes(handle)
                      )
                        return (
                          <ProductCard
                            key={index}
                            index={index}
                            product={product}
                            className={`font-poppins flex flex-col bg-white px-8 pt-12 ${
                              index === categories.length - 1 ? "pb-16" : ""
                            }`}
                          />
                        );
                    })}
                  </div>
                  <div
                    className={`${
                      handle ? "hidden" : ""
                    } flex flex-col items-center px-8 pt-16 md:hidden`}
                  >
                    {collections.map((collection: any, index: any) => (
                      <div
                        key={index}
                        className="mb-5 flex w-full max-w-[450px] flex-col items-center gap-8 bg-black pb-7 pt-12 text-white"
                      >
                        <span className="text-4xl font-semibold uppercase">
                          {collection?.title}
                        </span>
                        <LinkWrapper href={collection?.handle}>
                          <button className="border border-white px-5 py-1.5 text-sm">
                            Check Graphics
                          </button>
                        </LinkWrapper>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {area !== "apparel" && (
                <div
                  className={`${
                    handle ? "hidden" : ""
                  } flex flex-col items-center px-8 py-16 md:hidden`}
                >
                  {collections.map(
                    (collection: any, index: any) =>
                      collection.title !== "Seat Covers" &&
                      collection.title !== "Accessories" &&
                      collection.title !== "Apparel" &&
                      collection.title !== "Hoodies" &&
                      collection.title !== "Caps" &&
                      collection.title !== "T-shirts" && (
                        <div
                          key={index}
                          className="mb-5 flex w-full max-w-[450px] flex-col items-center gap-8 bg-black pb-7 pt-12 text-white"
                        >
                          <span className="text-4xl font-semibold uppercase">
                            {collection?.title}
                          </span>
                          <LinkWrapper href={`${area}/${collection?.handle}`}>
                            <button className="border border-white px-5 py-1.5 text-sm">
                              {area === "mx-graphics"
                                ? translate("checkMxGraphics")
                                : ""}
                              {area === "seat-covers"
                                ? translate("checkSeatCovers")
                                : ""}
                              {area === "apparel"
                                ? translate("checkApparel")
                                : ""}
                              {area === "accessories"
                                ? translate("checkAccessories")
                                : ""}
                            </button>
                          </LinkWrapper>
                        </div>
                      )
                  )}
                  {collections.size}
                </div>
              )}
              {area === "apparel" && (
                <div
                  className={`${
                    handle ? "hidden" : ""
                  } flex flex-col items-center px-8 py-16 md:hidden`}
                >
                  {["hoodie", "t-shirt", "cap"].map(
                    (category: any, index: any) => (
                      <div
                        key={index}
                        className="mb-5 flex w-full max-w-[450px] flex-col items-center gap-8 bg-black pb-7 pt-12 text-white"
                      >
                        <span className="text-4xl font-semibold uppercase">
                          {translate(`${category}s`)}
                        </span>
                        <LinkWrapper
                          href={`${
                            category !== "t-shirt"
                              ? area + "/" + category + "s"
                              : area + "/t-shirts"
                          }`}
                        >
                          <button className="border border-white px-5 py-1.5 text-sm">
                            {translate("checkApparel")}
                          </button>
                        </LinkWrapper>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>

            <div className="md:hidden">
              {handle && (
                <Producers
                  area={area}
                  collections={collections}
                  handle={handle ? handle : undefined}
                />
              )}
              {handle && <RecentlyAdded area={area} products={products} />}
            </div>

            {handle && (
              <div className="hidden md:block">
                <div className="mx-8 flex flex-col items-center border-b-2 border-b-[#f0f0f0]">
                  <p className="text-textdark mt-8 block text-lg font-semibold uppercase">
                    {area === "mx-graphics" && translate("compatibleGraphics")}
                    {area === "seat-covers" && translate("similarCovers")}
                    {area === "apparel" && translate("similarApparel")}
                    {area === "accessories" && translate("similarAccessories")}
                  </p>
                  <p className="pb-10 text-center text-sm">
                    {translate("description")}
                  </p>
                </div>
                <div
                  className={`${
                    handle ? "" : "hidden"
                  } grid-cols-1 pb-16 md:grid lg:px-8 xl:grid-cols-2`}
                >
                  {products.map((product: any, index: any) => {
                    if (
                      product.tags.includes(area) &&
                      !product.tags.includes(handle)
                    )
                      return (
                        <ProductCard
                          key={index}
                          index={index}
                          product={product}
                          className={`font-poppins flex flex-col bg-white px-8 pt-12 ${
                            index === categories.length - 1 ? "pb-16" : ""
                          }`}
                        />
                      );
                  })}
                </div>
                <div
                  className={`${
                    handle ? "hidden" : ""
                  } flex flex-col items-center px-8 pt-16 md:hidden`}
                >
                  {collections.map((collection: any, index: any) => (
                    <div
                      key={index}
                      className="mb-5 flex w-full max-w-[450px] flex-col items-center gap-8 bg-black pb-7 pt-12 text-white"
                    >
                      <span className="text-4xl font-semibold uppercase">
                        {collection?.title}
                      </span>
                      <LinkWrapper href={collection?.handle}>
                        <button className="border border-white px-5 py-1.5 text-sm">
                          Check Graphics
                        </button>
                      </LinkWrapper>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
