import { memo } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { LinkWrapper } from '../../../../apps/web/app/components/linkWrapper'
import useTranslate from '../../../../apps/web/app/hooks/useTranslate'

// const producers = [
//     'Yamaha',
//     'Suzuki',
//     'KTM',
//     'Kawasaki',
//     'Husquvarna',
//     'Honda',
//     'Beta',
// ]

export const Producers = memo(
    ({
        collections,
        handle,
        area,
    }: {
        collections: any
        handle?: any
        area?: any
    }) => {
        const { translate } = useTranslate()
        return (
            <div className="font-montserrat bg-white px-8 pt-10 text-[15px]">
                <p className="font-montserrat border-b-[3px] border-black py-1.5 font-medium uppercase">
                    {translate('choose')}{' '}
                    {area === 'apparel'
                        ? translate('category')
                        : translate('producer')}
                </p>
                {area !== 'apparel' && (
                    <ul className="mt-2 uppercase">
                        {collections.map(
                            (collection: any, index: any) =>
                                collection.title !== 'Accessories' &&
                                collection.title !== 'Seat Covers' &&
                                collection.title !== 'Apparel' &&
                                collection.title !== 'Hoodies' &&
                                collection.title !== 'T-shirts' &&
                                collection.title !== 'Caps' && (
                                    <li
                                        key={index}
                                        className="flex gap-4 border-b-2 border-[#f0f0f0] p-2 uppercase"
                                    >
                                        <ChevronRightIcon
                                            className={`w-4 ${
                                                handle === collection.handle
                                                    ? 'text-neongreen'
                                                    : ''
                                            }`}
                                        />
                                        <LinkWrapper
                                            href={`${area}/${collection.handle}`}
                                        >
                                            <span
                                                className={`${
                                                    handle === collection.handle
                                                        ? 'text-neongreen font-semibold'
                                                        : ''
                                                }`}
                                            >
                                                {collection.title}
                                            </span>
                                        </LinkWrapper>
                                    </li>
                                )
                        )}
                    </ul>
                )}
                {area === 'apparel' && (
                    <ul className="mt-2 uppercase">
                        {collections.map(
                            (collection: any, index: any) =>
                                (collection.title === 'Hoodies' ||
                                    collection.title === 'T-shirts' ||
                                    collection.title === 'Caps') && (
                                    <li
                                        key={index}
                                        className="flex gap-4 border-b-2 border-[#f0f0f0] p-2 uppercase"
                                    >
                                        <ChevronRightIcon
                                            className={`w-4 ${
                                                handle === collection.handle
                                                    ? 'text-neongreen'
                                                    : ''
                                            }`}
                                        />
                                        <LinkWrapper
                                            href={`${area}/${collection.handle}`}
                                        >
                                            <span
                                                className={`${
                                                    handle === collection.handle
                                                        ? 'text-neongreen font-semibold'
                                                        : ''
                                                }`}
                                            >
                                                {collection.title}
                                            </span>
                                        </LinkWrapper>
                                    </li>
                                )
                        )}
                    </ul>
                )}
            </div>
        )
    }
)
